






























































import { Observer } from "mobx-vue";
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { MyCharacterViewmodel } from "../viewmodels/my-character-viewmodel";

@Observer
@Component({
  components: {
    CharacterCard: () =>
      import("@/modules/my-character/components/character-card.vue"),
    CharacterRow: () =>
      import("@/modules/my-character/components/character-row.vue"),
  },
})
export default class MyCharacterList extends Vue {
  @Inject() vm!: MyCharacterViewmodel;
}
